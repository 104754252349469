export const environment = {
    production: false,
    firebaseConfig : {
        apiKey: "AIzaSyAWYL3j3jhD_Li6BowDm1jHkkepDDc28aA",
        authDomain: "scholae-webpage.firebaseapp.com",
        projectId: "scholae-webpage",
        storageBucket: "scholae-webpage.appspot.com",
        messagingSenderId: "676236015868",
        appId: "1:676236015868:web:64910d6bf8421d0e2d3f79",
        measurementId: "G-G3SDDBWY2N"
    }
};