import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specializing',
  templateUrl: './specializing.component.html'
})
export class SpecializingComponent implements OnInit {

  titleSectionProp: any = {
    title: 'Planes',
    subtitle: 'Consideraciones',
    list: [
      'Si necesitas un plan que se ajuste específicamente a tus requerimientos, no dudes en contactarnos.',
      'El plan es sin contrato fijo, puede darse de baja en cualquier momento (15 días de anticipación).'
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
