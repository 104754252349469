import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html'
})
export class PartnerComponent implements OnInit {

  List: any[] = [
    {
      title: 'Ventas',
      item: [
        "Centraliza y optimiza la gestión de tus ventas con una plataforma que integra todos los puntos de contacto con tus clientes."
      ],
      image: './assets/images/simply/ventas.png',
      width: 150
    },
    {
      title: 'Facturación Electrónica',
      item: [
        "Automatiza y agiliza el proceso de facturación de tu negocio cumpliendo con las normativas fiscales."
      ],
      image: './assets/images/simply/dte.png',
      width: 150
    },
    {
      title: 'Cuentas por Cobrar',
      item: [
        "Automatiza y controla tus cuentas por cobrar para mejorar el flujo de caja de tu empresa.",
      ],
      image: './assets/images/simply/cuentas-por-cobrar.png',
      width: 150
    },
    {
      title: 'Créditos',
      item: [
        "Administra de manera sencilla y eficaz los créditos otorgados a tus clientes."
      ],
      image: './assets/images/simply/credito.png',
      width: 150
    },
    {
      title: 'Inventario',
      item: [
        "Gestiona el control de existencias en tiempo real, optimizando la rotación de productos y evitando el desabastecimiento."
      ],
      image: './assets/images/simply/inventario.png',
      width: 150
    },
    {
      title: 'Bodega',
      item: [
        "Administra eficazmente tus bodegas con un sistema que facilita el seguimiento del almacenamiento, distribución y control de mercancías."
      ],
      image: './assets/images/simply/bodega.png',
      width: 150
    },
    {
      title: 'Sucursales',
      item: [
        "Gestiona múltiples sucursales de manera centralizada, garantizando que todas operen de forma coordinada y bajo un mismo sistema."
      ],
      image: './assets/images/simply/sucursales.png',
      width: 150
    },
    {
      title: 'Gastos',
      item: [
        "Lleva un control exhaustivo de todos los gastos de tu empresa, asegurando una gestión financiera eficiente."
      ],
      image: './assets/images/simply/gastos.png',
      width: 150
    },
    {
      title: 'Reportes y Dashboard',
      item: [
        "Te permite visualizar en tiempo real los indicadores clave de tus operaciones, detectar tendencias y analizar el comportamiento de las ventas, inventarios y finanzas."
      ],
      image: './assets/images/simply/reporte.png',
      width: 150
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}
