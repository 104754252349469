import { Component, OnInit } from '@angular/core';
declare var $: any; // jQuery para controlar el modal
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  currentPricingImage: string = '';
  titleSectionProp: any = {
    class: 'text-center',
    title: 'Precios',
    subTitle: 'Planes de Servicio',
  };

  List: any[] = [
    {
      title: "Básico",
      services: [
        { icon: "fa fa-calculator", label: "Caja" },
        { icon: "fa fa-shopping-cart", label: "Compras" },
        { icon: "fa fa-shopping-bag", label: "Ventas" },
        { icon: "fa fa-archive", label: "Ajustes de Inventario" },
        { icon: "fa fa-pie-chart", label: "Dasboard" },
      ],
      nonservices: [],
      pricingImage: "assets/images/simply/basic.jpg"
    },
    {
      title: "Intermedio",
      services: [
        { icon: "fa fa-calculator", label: "Caja" },
        { icon: "fa fa-shopping-cart", label: "Compras" },
        { icon: "fa fa-shopping-bag", label: "Ventas" },
        { icon: "fa fa-archive", label: "Ajustes de Inventario" },
        { icon: "fa fa-pie-chart", label: "Dasboard" },
        { icon: "fa fa-suitcase", label: "Cuentas por Cobrar" },
        { icon: "fa fa-file-text", label: "DTEs" },
      ],
      nonservices: [],
      pricingImage: "assets/images/simply/inter.jpg"
    },
    {
      title: "Pro",
      services: [
        { icon: "fa fa-calculator", label: "Caja" },
        { icon: "fa fa-shopping-cart", label: "Compras" },
        { icon: "fa fa-shopping-bag", label: "Ventas" },
        { icon: "fa fa-archive", label: "Ajustes de Inventario" },
        { icon: "fa fa-suitcase", label: "Cuentas por Cobrar" },
        { icon: "fa fa-truck", label: "Traslados" },
        { icon: "fa fa-pie-chart", label: "Dasboard" },
        { icon: "fa fa-file-text", label: "DTEs" },
        { icon: "fa fa-money", label: "Gastos" }
      ],
      nonservices: [],
      pricingImage: "assets/images/simply/pro.jpg"
    }
  ];

  openPricingImage(imageUrl: string) {
    this.currentPricingImage = imageUrl; // Asigna la imagen correcta
    $('#pricingModal').modal('show'); // Abre el modal
  }

  constructor() { }

  ngOnInit() { }

}
