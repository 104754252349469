import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html'
})
export class AboutUsComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    title: 'INFORMACIÓN ADICIONAL',
    subTitle: 'Nuestras Políticas'
  };

  List: any[] = [
    {
      title: 'Seguridad de la información',
      subtitle: 'Información almacenada en la plataforma es confidencial y no se comparte con ninguna otra entidad.',
      image: './assets/images/scholae/shield.png'
    },
    {
      title: 'Actualizaciones gratuitas',
      subtitle: 'Simply es una plataforma que se provee como servicio, por lo tanto, las actualizaciones y mejoras realizadas sobre los módulos ya contratados no tienen costo adicional.',
      image: './assets/images/scholae/refresh.png'
    },
    {
      title: 'Implementación',
      subtitle: 'El proceso de implementación puede tardar entre 2 y 3 días, dependiendo de la cantidad de la información proporcionada por el cliente.',
      image: './assets/images/scholae/plan.png'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
